import React, { useState, useEffect } from "react";
import styled from "styled-components";

import ProductCard from "../component/ProductCard";
import Ecocheque from "../assets/ecocheque.png";

import { Row, Col, Typography } from "antd";
import api from "../api";

const { Title } = Typography;

const PelletPage = () => {
  const [pellets, setPellets] = useState([]);
  const [loading, setLoading] = useState(false);

  const PriceRemark = styled.div`
    clear: both;
    font-size: 14px;
    width: 100%;
    text-align: center;
  `;

  const EcoImage = styled.div`
    height: 100px;
    width: 100px;
    margin: 0 auto;

    background: url(${Ecocheque});
    background-repeat: no-repeat;
  `;

  useEffect(() => {
    if (!loading && pellets.length === 0) {
      setLoading(true);

      api.getProductData().then((response) => {
        response.data.forEach((item) => {
          item.price = parseFloat(item.price);
          item.discount = parseFloat(item.discount);
          item.discountPrice = parseFloat(item.price) - parseFloat(item.discount);
          item.amount = parseFloat(item.amount);
          item.pallet = parseFloat(item.pallet);
          item.sale = item.sale === "1";
          item.stock = item.stock === "1";
          item.characteristics = item.characteristics.split(";");
        });

        response.data = response.data.filter(item => item.sale)
        setPellets(response.data);
        setLoading(false);
      });
    }
  }, [pellets, setPellets, loading, setLoading]);

  return (
    <>
      <Row align="middle" span={24}>
        <Col span={24} style={{ textAlign: "center", marginTop: 75 }}>
          <Title id="pellets">Onze Pellets</Title>

          <Title level={5}>Gelieve vooraf te bellen bij afhaling van een volledige pallet.</Title>
        </Col>
      </Row>

      <Row wrap="true" span={24} align="center">
        {pellets.map((product) => (
          <Col
            key={product.id}
            xs={{ span: 24 }}
            md={{ span: 7 }}
            style={{ minWidth: "320px", margin: 5 }}
          >
            <ProductCard
              key={product.id}
              product={product}
              className="products"
            />
          </Col>
        ))}

        <PriceRemark>
          * Prijzen pellets zijn per pallet en bij afhaling.
        </PriceRemark>
      </Row>

      <Row wrap="false" align="center">
        <Col span={24} style={{ textAlign: "center" }}>
          <EcoImage />
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <p>Wij aanvaarden ecocheques voor alle pellet aankopen (max 250€)</p>
        </Col>
      </Row>
    </>
  );
};

export default PelletPage;
