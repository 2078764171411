import React from 'react';
import { Row, Col, Typography } from 'antd';

const { Title } = Typography;

const ContactPage  = () => {
  return (
    <>
      <Row align="middle" width="100%">
        <Col span={24} style={{ textAlign: 'center', marginTop: 75 }}>
          <Title id="address">Waar kan je ons vinden?</Title>
          <p>Je kan ons vinden op volgend adres of ons bereiken via verschillende platvormen.</p>
        </Col>
      </Row>

      <Row span={24} textalign="center">
          <iframe width="100%" height="500px" title="ourAddress"
                  src="https://maps.google.com/maps?q=Brandstoffen%20Geert%20Roels,%20Katstraat%2041%20Lede&t=&z=17&ie=UTF8&iwloc=&output=embed" 
                  frameBorder="0" scrolling="no" 
                  marginHeight="0" marginWidth="0" />
      </Row>

      <Row span={24} textalign="center">
        <Col height="20vh" xs={{ span: 24 }} md={{ span: 8 }}>
          <Col span={6} style={{ float: 'left', marginTop: 20, height: 170 }}>
            <span className="material-icons blue largeIcon">location_on</span>
          </Col>

          <Col span={18} style={{ marginTop: 10, minWidth: 210  }}>
            <p>Brandstoffen Geert Roels</p>
            <p>Katstraat 41</p>
            <p>9340 Lede</p>
            <p>BE 1008.284.118</p>
          </Col>
        </Col>
        <Col height="20vh" xs={{ span: 24 }} md={{ span: 8 }}>
          <Col span={6} style={{ float: 'left', marginTop: 20, height: 170 }}>
            <span className="material-icons blue largeIcon">mail</span>
          </Col>

          <Col span={18} style={{ marginTop: 10, minWidth: 210  }}>
            <a href="mailto:g.roels@skynet.be"><p>g.roels@skynet.be</p></a>
            <a href="tel:053805164"><p>053/80.51.64</p></a>
          </Col>
        </Col>
        <Col height="20vh" xs={{ span: 24 }} md={{ span: 8 }}>
          <Col span={6} style={{ float: 'left', marginTop: 20, height: 170 }}>
            <span className="material-icons blue largeIcon">store</span>
          </Col>

          <Col span={18} style={{ marginTop: 10, minWidth: 210 }}>
            <p>Dinsdag, woensdag & vrijdag: 8u30 - 12u & 13u30 - 18u</p>
            <p>Donderdag: 8u30 - 12u</p>
            <p>Zaterdag: 8u30 - 16u</p>
            <p>Zondag & Maandag Gesloten</p>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default ContactPage