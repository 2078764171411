import React, { useContext } from "react";
import { bool } from "prop-types";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { CartContext } from "../../contexts/Cart/CartContext";
import { Row, Col, Drawer, Typography } from "antd";

// Components
import CartItem from "./CartItem";

const { Title } = Typography;
const Button = styled.div`
  color: #fff;
  background-color: #fd5e33;
  width: 380px;
  height: 60px;

  border-radius: 5px;

  font-size: 24px;
  font-weight: 600;
  line-height: 60px;

  text-align: center;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  left: 24px;
`;

const Cart = ({ open, functionCallFromParent, ...props }) => {
  const { cartItems, subTotal, deposit } = useContext(CartContext);

  const returnToSite = () => (document.location.href = "/");

  const validTo = new Date();
  const validFrom = localStorage.getItem("cartValidity");
  const timeDifference = 3600000 - (validTo.getTime() - validFrom);

  if (timeDifference < 0 && cartItems.length > 0) {
    returnToSite();
  }

  return (
    <Drawer
      title="Mijn winkelmandje"
      width={420}
      onClose={functionCallFromParent}
      visible={open}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            height: "270px",
            textAlign: "left",
          }}
        >
          <Row>
            <Title level={5}>Om al onze klanten van dienst te kunnen zijn, beperken we tijdelijk de bestelhoeveelheid tot 1 pallet per aankoop.</Title>
          </Row>
          <Row>
            <Col span={12}>
              <p>Waarborg pallet:</p>
            </Col>
            <Col span={12}>
              <p>€{deposit}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p>Subtotaal:</p>
            </Col>
            <Col span={12}>
              <p>€{subTotal}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p>Levering:</p>
            </Col>
            <Col span={12}>
              <p>Bereken de levering op de bestelpagina</p>
            </Col>
          </Row>
          <Row>
            {cartItems.length > 0 ? (
              <NavLink to="/checkout">
                <Button>Bestellen</Button>
              </NavLink>
            ) : (
              <Button>Bestellen</Button>
            )}
          </Row>
        </div>
      }
    >
      {cartItems.length > 0 ? (
        cartItems.map((item) => <CartItem key={item.id} product={item} />)
      ) : (
        <p>Winkelmandje is leeg, voeg een artikel toe om verder te gaan</p>
      )}
    </Drawer>
  );
};

Cart.propTypes = {
  open: bool.isRequired,
};

export default Cart;
