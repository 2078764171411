import React, { useState, useEffect } from "react";
import { Layout, Typography, Row, Col } from "antd";

// Components
import Header from "../component/Header";
import Footer from "../component/Footer";

// Cart
import Cart from "../component/Cart/Cart";

// Subpages
import Home from "../pages/Home";
import Pellets from "../pages/Pellets";
import Address from "../pages/Address";
import Contact from "../pages/Contact";
import api from "../api";

const { Title } = Typography;

const LandingPage = () => {
  const { Content } = Layout;
  const [openCart, setOpenCart] = useState(false);
  const parentFunction = () => setOpenCart(!openCart);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const getNotes = async () => api.getQuickNotes().then((notes) => {
      notes.data.forEach((note) => {
        note.description = note.description.split(";");
      })
      setNotes(notes.data.filter(note => note.active === "1"))
    });
    getNotes();
  }, []);

  /* <!--Col span={24} style={{ textAlign: "center", marginTop: 75 }}>
            <Title level={3}>Om al onze klanten van dienst te kunnen zijn, beperken we tijdelijk de bestelhoeveelheid:</Title>
            <Title level={4}>- Maximaal 1 pallet per aankoop/klant</Title>
            <Title level={4}>- Betaling uiterlijk 1 week na bestelling</Title>
            <Title level={4}>- Afhalen of leveren binnen de 30 dagen</Title>
          </Col--> */

  return (
    <>
      <Header open={openCart} functionCallFromParent={parentFunction} />
      <Cart
        open={openCart}
        functionCallFromParent={parentFunction}
        setOpenCart={setOpenCart}
      />
      <Home />

      <Content
        className="site-layout"
        style={{ width: "90%", maxWidth: "1300px", margin: "0 auto" }}
      >
        <Row align="middle" span={24}>
          {notes.length > 0 &&
            notes.map((note) => (
              <Col span={24} style={{ textAlign: "center", marginTop: 75 }} key={note.id}>
                <Title level={2}>{note.title}</Title>

                {note.description && note.description.length > 1 ?
                  (note.description.map(desc => <Title level={4}>- {desc}</Title>)) :
                  (<Title level={3}>{note.description}</Title>)}

              </Col>
            ))}

        </Row>

        <Pellets />
        <Address />
        <Contact />
      </Content>

      <Footer />
    </>
  );
};

export default LandingPage;
