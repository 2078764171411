import React, { Suspense, lazy, Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ReactDOM from "react-dom";
import "./index.css";

import CartContextProvider from './contexts/Cart/CartContext';

import LandingPage from "./pages/LandingPage";

const Checkout = lazy(() => import("./pages/Checkout"));
const Payment = lazy(() => import("./pages/Payment"));
const Privacy = lazy(() => import("./pages/Privacy"));

class App extends Component {
  componentDidMount = () => window.addEventListener('scroll', this.handleScroll);
  componentWillUnmount = () => window.removeEventListener('scroll', this.handleScroll);

  handleScroll = (event) => {
    let home = document.getElementById("toolbar");

    if (home) {
      if (window.scrollY > 0) {
        if (!home.className.includes("sticky")) {
          home.className = home.className + " sticky";
        }
      } else {
        home.className = home.className.replaceAll(" sticky", "");
      }
    }
  }

  render = () => (
    <CartContextProvider>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/checkout" component={Checkout} />
            <Route path="/payment" component={Payment} />
            <Route path="/privacy" component={Privacy} />
            <Route component={LandingPage} />
          </Switch>
        </Suspense>
      </Router>
    </CartContextProvider>);
};

ReactDOM.render(<App />, document.getElementById("root"));