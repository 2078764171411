import axios from "axios";

const instance = axios.create({
  baseURL: "https://www.pelletsroelsgeert.be/api",
});

const header = {
  CART: "7+JpL}Je:y9UP3[hK",
};

const api = {
  // Retrievals
  getQuickNotes: () =>
    instance({
      method: "GET",
      url: "/home/read.php",
      transformResponse: [(data) => (data === "[]" ? data : JSON.parse(data))],
    }),

  getPayment: (order) =>
    instance({
      method: "GET",
      url: "/orders/single_read.php",
      params: order,
      headers: header,
      transformResponse: [(data) => JSON.parse(data)],
    }),

  getPaymentStatus: (paymentId) =>
    instance({
      method: "GET",
      url: "/payments/single_read.php",
      params: {
        id: paymentId,
      },
      transformResponse: [(data) => JSON.parse(data)],
    }),

  getLocation: (search) =>
    instance({
      method: "POST",
      url: "/locations/search.php",
      data: search,
      transformResponse: [(data) => JSON.parse(data)],
    }),

  // Creations
  createClient: (client) =>
    instance({
      method: "POST",
      url: "/clients/create.php",
      data: client,
      headers: header,
      transformResponse: [(data) => JSON.parse(data)],
    }),

  createOrder: (order) =>
    instance({
      method: "POST",
      url: "/orders/create.php",
      data: order,
      headers: header,
      transformResponse: [(data) => JSON.parse(data)],
    }),

  // Updates
  updateClient: (client) =>
    instance({
      method: "POST",
      url: "/clients/update.php",
      data: client,
      headers: header,
      transformResponse: [(data) => JSON.parse(data)],
    }),

  getProductData: () =>
    instance({
      method: "GET",
      url: "/products/read.php",
      transformResponse: [(data) => JSON.parse(data)],
    }),
};

export default api;
