import React, { useContext, useState } from "react";

import { CartContext } from "../../contexts/Cart/CartContext";
import { object } from "prop-types";
import { Row, Col, Image, Divider, Typography } from "antd";
import { MinusOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const CartItem = ({ ...props }) => {
  const product = props.product;
  const [image, setImage] = useState();

  import(`../../assets/products/${product.image}`).then((image) =>
    setImage(image.default)
  );

  const { removeProduct, increase, decrease, cartItems } = useContext(CartContext);
  const getCartCount = () => cartItems.reduce((total, item) => total + item.quantity, 0);
  const removedEmpty = (product) => {
    if (product.quantity === 1) {
      removeProduct(product);
    } else {
      decrease(product);
    }
  };

  return (
    <Row span={24}>
      <Col span={5} height="100px">
        <Image width="64px" src={image} preview={false} />
      </Col>

      <Col span={13} height="100px">
        <Title level={4} className="gray">
          {product.title}
        </Title>

        {product.quantity === 0 ? 
          (<p className="biggerIcon"></p>) : 
          (<MinusOutlined className="biggerIcon" style={{ float: "left" }} onClick={() => removedEmpty(product)}/>)}

        <p style={{ float: "left", marginTop: 15 }}>{product.quantity}</p>
        {getCartCount() < 10 && (<PlusOutlined
          className="biggerIcon"
          onClick={() => increase(product)}
        />)}
      </Col>

      <Col span={6} offset={0} height="100px">
        <Title level={4} className="gray">
          €{product.price * product.quantity}
        </Title>
        <DeleteOutlined
          className="biggerIcon"
          onClick={() => removeProduct(product)}
        />
      </Col>
      <Divider />
    </Row>
  );
};

CartItem.propTypes = {
  product: object.isRequired,
};

export default CartItem;
