import React, { createContext, useReducer } from "react";
import { CartReducer } from "./CartReducer";

export const CartContext = createContext();

let storage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];
const validTo = new Date();
const validFrom = localStorage.getItem("cartValidity");

const CartContextProvider = ({ children }) => {
  let timeDifference = validTo.getTime() - validFrom;
  if (!validFrom || timeDifference > 3600000) {
    storage = [];
  }

  const initialState = {
    cartItems: storage,
    subTotal: storage.reduce(
      (total, product) => total + product.price * product.quantity,
      0
    ),
    itemCount: storage.reduce((total, product) => total + product.quantity, 0),
    deposit: storage.reduce(
      (total, product) => total + product.pallet * product.quantity,
      0
    ),
    depositPieces: storage.reduce(
      (total, product) => total + (product.pallet > 0 ? product.quantity : 0),
      0
    ),
    delivery: storage.delivery,
    total: storage.reduce(
      (total, product) =>
        total +
        product.pallet * product.quantity +
        product.price * product.quantity,
      0
    ),
  };

  const [state, dispatch] = useReducer(CartReducer, initialState);

  const increase = (payload) => {
    dispatch({ type: "INCREASE", payload });
  };

  const decrease = (payload) => {
    dispatch({ type: "DECREASE", payload });
  };

  const addProduct = (payload) => {
    dispatch({ type: "ADD_ITEM", payload });
  };

  const removeProduct = (payload) => {
    dispatch({ type: "REMOVE_ITEM", payload });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR" });
  };

  const handleCheckout = () => {
    console.log("CHECKOUT", state);
    dispatch({ type: "CHECKOUT" });
  };

  const handleDelivery = (payload) => {
    dispatch({ type: "DELIVERY", payload });
  };

  const contextValues = {
    removeProduct,
    addProduct,
    increase,
    decrease,
    clearCart,
    handleCheckout,
    handleDelivery,
    ...state,
  };

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
