import React, { useContext, useState } from "react";

import { CartContext } from "../contexts/Cart/CartContext";
import { object } from "prop-types";

import { Card, Typography, Image } from "antd";

const { Title } = Typography;
const { Meta } = Card;

const ProductCard = ({ ...props }) => {
  const product = props.product;
  const { increase, addProduct, cartItems } = useContext(CartContext);
  const [image, setImage] = useState();

  import(`../assets/products/${product.image}`).then((image) =>
    setImage(image.default)
  );

  const getCartCount = () => cartItems.reduce((total, item) => total + item.quantity, 0);
  const increaseProduct = (product) => {
    const itemExists = cartItems.find((prod) => prod.id === product.id);

    if (getCartCount() < 10) { 
      if (itemExists) {
        if (itemExists.quantity < 10) {
          increase(product);
        }
      } else {
        if (cartItems.length < 10) {
          addProduct(product);
        }
      }
    }
  };

  return (
    <Card
      className="productCard"
      actions={[
        <div className="price">
          {product.discount > 0 ? (
            <Title level={4}>
              €{product.price} - <b className="orange">€{product.discount}</b>
            </Title>
          ) : (
              <Title level={4}>€{product.price} <Title level={5}>(€{product.unitprice} per zak)</Title></Title>
          )}
        </div>,
        <div>
          {getCartCount() < 10 && (<div>
            {
              (!product.stock) ?
                ((product.image === "kolen.jpg") ? (<p>Afgehaald</p>) : (<p>Geen voorraad</p>)) :
                (<p className="infoButton" onClick={() => increaseProduct(product)}>
                  <span className="material-icons orange">add_shopping_cart</span>
                </p>)}
          </div>)}
        </div>,
      ]}
      hoverable="true"
    >
      <Meta
        avatar={
          <Image alt={product.title} src={image} height="230px" width="140px" />
        }
        title={<Title level={5}>{product.title}</Title>}
        description={
          <div style={{ height: "170px" }}>
            {product.characteristics.length > 0 ? (
              <p style={{ lineHeight: "12px" }}>Eigenschappen</p>
            ) : (
              <p style={{ lineHeight: "15px" }}>{product.description}</p>
            )}
            {product.characteristics.map((characteristic, index) => (
              <p style={{ lineHeight: "12px" }} key={index}>
                <span className="orange">-</span> {characteristic}
              </p>
            ))}
          </div>
        }
      />
    </Card>
  );
};

ProductCard.propTypes = {
  product: object.isRequired,
};

export default ProductCard;
