const Storage = (cartItems) => {
  const validFrom = new Date();
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
  localStorage.setItem("cartValidity", validFrom.getTime());
};

export const sumItems = (cartItems, delivery) => {
  const deliveryPrice = delivery || 0;
  Storage(cartItems);

  return {
    itemCount: cartItems.reduce(
      (total, product) => total + product.quantity,
      0
    ),
    subTotal: cartItems.reduce(
      (total, product) => total + product.price * product.quantity,
      0
    ),
    deposit: cartItems.reduce(
      (total, product) => total + product.pallet * product.quantity,
      0
    ),
    depositPieces: cartItems.reduce(
      (total, product) => total + (product.pallet > 0 ? product.quantity : 0),
      0
    ),
    delivery: deliveryPrice,
    total:
      cartItems.reduce(
        (total, product) =>
          total +
          product.pallet * product.quantity +
          product.price * product.quantity,
        0
      ) + deliveryPrice,
  };
};

export const CartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      if (!state.cartItems.find((item) => item.id === action.payload.id)) {
        state.cartItems.push({
          ...action.payload,
          quantity: 1,
        });
      }

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case "REMOVE_ITEM":
      return {
        ...state,
        ...sumItems(
          state.cartItems.filter((item) => item.id !== action.payload.id)
        ),
        cartItems: [
          ...state.cartItems.filter((item) => item.id !== action.payload.id),
        ],
      };
    case "INCREASE":
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === action.payload.id)
      ].quantity++;
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case "DECREASE":
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === action.payload.id)
      ].quantity--;
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case "CHECKOUT":
      return {
        cartItems: [],
        checkout: true,
        ...sumItems([]),
      };
    case "DELIVERY":
      return {
        ...state,
        ...sumItems(state.cartItems, action.payload),
        cartItems: [...state.cartItems],
      };
    case "CLEAR":
      return {
        cartItems: [],
        ...sumItems([]),
      };
    default:
      return state;
  }
};
