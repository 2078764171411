import React from 'react';
import { Row, Col, Typography, Image } from 'antd';

import qrcode from "../assets/qrcode.svg";

const { Title } = Typography;

const ContactPage  = () => {
  return (
    <>
      <Row span={24} textalign="center"> 
        <Col span={24} style={{ textAlign: 'center', marginTop: 75 }}>
          <Title id="contact">Contacteer ons</Title>
          
          <p>Heb je een vraag of opmerking? Wil je een bepaald product bestellen, contacteer ons zeker via dit formulier</p>
          <p>Scan onze QR of klik erop en ga naar ons contact formulier</p>
        </Col>
      </Row>

      <Row span={24} textalign="center"> 
        <Col span={24} style={{ textAlign: 'center' }}>
          <a href="https://us1.list-manage.com/contact-form?u=1d8cb7651e831b2965c558087&form_id=168f5886b52e8ce221691a9b54164427">
            <Image preview={false} src={qrcode} width={200} />
          </a>
        </Col>
      </Row>

    </>
  );
};

export default ContactPage