import React, { useState, useContext } from "react";
import { Link } from 'react-scroll'
import styled from "styled-components";

import { Drawer, Row, Typography, Badge } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { CartContext } from '../contexts/Cart/CartContext';
const { Title } = Typography;

const StickyRow = styled.div`
  overflow: hidden;

  background-color: #212573;
  position: relative;
`;

const ToolBar = styled.div`
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;

  h1 {
    vertical-align: middle;
    color: #fff;
    float: left;
    line-height: 50px;
    margin-left: 15px;
    width: 30%;
    min-width: 320px;
  }

  span.menu {
    color: #fff;
    font-size: 50px;
    float: right;
    right: 45px;
    top: 15px;
    cursor: pointer;
  }

  @media (min-width: 885px) {
    span.menu {
      display: none;
    }
  }
`;

const Navigation = styled.ul`
  width: 532px;
  float: right;
  height: 50px;
  line-height: 50px;

  li {
    list-style: none;
    position: relative;
    float: left;
    width: 80px;
    margi-top: 10px;
  }

  a {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
  }

  li:hover  a:before {
    position: absolute;
    content: '';
    width: 32px;
    height: 4px;
    left: 30%;
    margin: -5px 5px;
    bottom: 0;
    background: -webkit-linear-gradient(left,rgba(255,255,255,0.9) 90%,white 100%);
    background: -o-linear-gradient(left,rgba(255,255,255,0.9) 90%,white 100%);
    background: linear-gradient(to right,rgba(255,255,255,0.9) 90%,white 100%);
    -webkit-transform: translate(-50%) scaleX(1.5);
    -moz-transform: translate(-50%) scaleX(1.5);
    -ms-transform: translate(-50%) scaleX(1.5);
    -o-transform: translate(-50%) scaleX(1.5);
    transform: translate(-50%) scaleX(1.5);
  }

  sup {

    margin-right: 13px;
    margin-top: 15px;

  }

  span.shop {
    position: relative;
    color: #fff;
    margin: 15px 20px 0px 20px;
    font-size: 28px;
    cursor: pointer;
  }
 
  @media (min-width: 375px) {
    display: none;
  }

  @media (min-width: 885px) {
    display: block;
  }
`;

const Header  = (...props) => {
  const { itemCount } = useContext(CartContext);
  const [open, setOpen] = useState(false);
  const childFunction = () => {
    setOpen(false);
    props[0].functionCallFromParent();
  };
  
  return (
    <StickyRow id="home">
      <StickyRow id="toolbar">   
        <ToolBar>
          <h1>Brandstoffen Geert Roels</h1>
          
          <Navigation>
            <li><Link to='home' spy={true} offset={-125}>Home</Link></li>
            <li><Link to='pellets' spy={true} offset={-125}>Pellets</Link></li>
            <li><Link to='address' spy={true} offset={-125}>Adres</Link></li>
            <li><Link to='contact' spy={true} offset={-125}>Contact</Link></li>
            <li>
                <Badge count={itemCount}>
                  <span className="material-icons shop" onClick={() => childFunction()}>shopping_cart</span>
                </Badge>
            </li>
          </Navigation>
          <span className="material-icons menu" onClick={() => setOpen(!open)}>menu</span>
        </ToolBar>
      </StickyRow>

      <Drawer width="100%" height="100vh" placement="top" key="top"
              onClose={() => setOpen(false)}
              closeIcon={<CloseOutlined style={{ fontSize: '24px', color: '#fff' }}  />}
              visible={open}              
              drawerStyle={{
                backgroundColor: '#212573',
                color: '#fff'
              }}>
        <Row span={24} style={{margin: '20% auto'}}>
          <Row span={24} align="middle" justify="center" style={{margin: '10px auto'}}>
            <Link to='home' spy={true} offset={-125} onClick={() => setOpen(false)}><Title level={2} type="white">Home</Title></Link>
          </Row>
          <Row span={24} align="middle" justify="center" style={{margin: '10px auto'}}>
            <Link to='pellets' spy={true} offset={-125} onClick={() => setOpen(false)}><Title level={2} type="white">Pellets</Title></Link>
          </Row>
          <Row span={24} align="middle" justify="center" style={{margin: '10px auto'}}>
            <Link to='address' spy={true} offset={-125} onClick={() => setOpen(false)}><Title level={2} type="white">Adres</Title></Link>
          </Row>
          <Row span={24} align="middle" justify="center" style={{margin: '10px auto'}}>
            <Link to='contact' spy={true} offset={-125}  onClick={() => setOpen(false)}><Title level={2} type="white">Contact</Title></Link>
          </Row>
          <Row span={24} align="middle" justify="center" style={{margin: '10px auto'}}>
            <Badge count={itemCount}>
              <Title level={2} type="white"><span className="material-icons shop" onClick={() => childFunction()}>shopping_cart</span></Title>
            </Badge>
          </Row>
        </Row>
      </Drawer>
    </StickyRow>
  );
}
export default Header;
