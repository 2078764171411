import React from "react";
import styled from "styled-components";

const footerImg = require("../assets/icons/footer.svg");

const Row = styled.footer`
  width: 100%;
  height: 150px;
  clear: both;

  background-image: url(${footerImg});
  background-size: cover;
  background-position: top center;

  h4 {
    border-top: 1px solid #eee;
    width: 100%;
    max-width: 1320px;
    text-align: center;
  }

  @media (min-width: 375px) {
    h4 {
      color: #fff;
      line-height: 150px;
      margin: 50px auto;
    }
  }

  @media (min-width: 768px) {
    h4 {
      color: #333;
      line-height: 80px;
      margin: 0 auto;
    }
  }

  @media (min-width: 1024) {
  }

`;

const Footer = () => {

  return (
    <Row>
          <h4>Brandstoffen Geert Roels (BE 1008.284.118) - All Rights Reserved.</h4>
    </Row>
  );
};

export default Footer;